exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-blog-js": () => import("./../../../src/pages/blog/blog.js" /* webpackChunkName: "component---src-pages-blog-blog-js" */),
  "component---src-pages-blog-blog-template-js": () => import("./../../../src/pages/blog/BlogTemplate.js" /* webpackChunkName: "component---src-pages-blog-blog-template-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-careers-business-development-executive-js": () => import("./../../../src/pages/careers/business-development-executive.js" /* webpackChunkName: "component---src-pages-careers-business-development-executive-js" */),
  "component---src-pages-careers-flutter-developer-js": () => import("./../../../src/pages/careers/flutter-developer.js" /* webpackChunkName: "component---src-pages-careers-flutter-developer-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-nodejs-developer-js": () => import("./../../../src/pages/careers/nodejs-developer.js" /* webpackChunkName: "component---src-pages-careers-nodejs-developer-js" */),
  "component---src-pages-careers-reactjs-developer-js": () => import("./../../../src/pages/careers/reactjs-developer.js" /* webpackChunkName: "component---src-pages-careers-reactjs-developer-js" */),
  "component---src-pages-careers-reactnative-developer-js": () => import("./../../../src/pages/careers/reactnative-developer.js" /* webpackChunkName: "component---src-pages-careers-reactnative-developer-js" */),
  "component---src-pages-careers-ruby-on-rails-developer-js": () => import("./../../../src/pages/careers/ruby-on-rails-developer.js" /* webpackChunkName: "component---src-pages-careers-ruby-on-rails-developer-js" */),
  "component---src-pages-careers-ui-ux-designer-js": () => import("./../../../src/pages/careers/ui-ux-designer.js" /* webpackChunkName: "component---src-pages-careers-ui-ux-designer-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-estimate-projects-js": () => import("./../../../src/pages/estimate-projects.js" /* webpackChunkName: "component---src-pages-estimate-projects-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-test-js": () => import("./../../../src/pages/index-test.js" /* webpackChunkName: "component---src-pages-index-test-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

